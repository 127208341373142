<template>
	<div class="staff">
		<!-- ##### Viral News Breadcumb Area Start ##### -->
		<div class="viral-news-breadcumb-area section-padding-50">
			<div class="container h-100">
				<div class="row h-100 align-items-center">
					<!-- Breadcumb Area -->
					<div class="col-12">
						<h3>自動車整備士メカニック（BMW整備／チューニング）</h3>
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb">
								<li class="breadcrumb-item"><a href="/">Home</a></li>
								<li class="breadcrumb-item">
									<a href="/recruit">求人・採用情報</a>
								</li>
								<li class="breadcrumb-item active" aria-current="page">
									自動車整備士メカニック（BMW整備／チューニング）
								</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</div>
		<!-- ##### Viral News Breadcumb Area End ##### -->

		<!-- ##### Contact Form Area Start ##### -->
		<div class="block section-padding-50">
			<div class="page container">
				<div class="row mb-3">
					<div class="col-6 col-lg-3 mb-3">
						<img
							class="img-responsive card-img-top"
							alt="技術職"
							src="/img/about/recruit/01_hazimeni.jpg"
						/>
					</div>
					<div class="col-6 col-lg-3 mb-3">
						<img
							class="img-responsive card-img-top"
							alt="技術職"
							src="/img/about/recruit/05_shokuba_no_image.jpg"
						/>
					</div>
					<div class="col-6 col-lg-3 mb-3">
						<img
							class="img-responsive card-img-top"
							alt="技術職"
							src="/img/about/recruit/02_assist.jpg"
						/>
					</div>
					<div class="col-6 col-lg-3 mb-3">
						<img
							class="img-responsive card-img-top"
							alt="技術職"
							src="/img/about/recruit/03_zigyou_naiyou.jpg"
						/>
					</div>
				</div>

				<div class="row">
					<div class="col-12">
						<h3>募集要項</h3>

						<div clss="row">
							<div class="col-12">
								<table class="table table-striped">
									<tbody>
										<tr>
											<th>業務内容</th>
											<td>
												<ul>
													<li>オイル交換などの軽整備</li>
													<li>メンテナンス ／車検整備</li>
													<li>アフターパーツ装着</li>
													<li>チューニング</li>
													<li>加工、工作、溶接</li>
													<li>
														イベントサポート（年2回 /
														希望に応じて増える場合もあります）
													</li>
												</ul>
											</td>
										</tr>
										<tr>
											<th>求める人材</th>
											<td>
												<p>
													<b
														>知識や経験のあるエキスパートメカニックは優遇します。より能力が発揮できるポジションを用意します。</b
													>
												</p>
												<table class="table table-bordered text-left">
													<tr>
														<th>職種</th>
														<th>概要</th>
														<th>資格</th>
													</tr>
													<tr>
														<td>メカニック（新卒）</td>
														<td>
															自動車整備専門学校、高等学校自動車科、大学短大自動車科、の新卒者。
														</td>
														<td>
															<ul>
																<li>３級整備士以上の資格</li>
																<li>
																	普通自動車免許（AT車限定不可）／車検整備
																</li>
															</ul>
														</td>
													</tr>
													<tr>
														<td>メカニック（チャレンジ）</td>
														<td>
															資格無しでも応募可能なチャレンジメカニック。
															メカニックとして適性があれば未経験でも採用します。
															DIYでの整備経験でスキルがあれば優遇します。
															整備経験が短い方やブランクがある方も応募可能です。
														</td>
														<td>
															<ul>
																<li>普通自動車免許（AT車限定不可）</li>
															</ul>
														</td>
													</tr>
													<tr>
														<td>メカニック（整備経験者）</td>
														<td>
															整備スキルに合わせて業務内容や配属を決めます。
															BMW特有の整備やチューニングは入社後に研修やアドバイスによって習得してもらいますので、
															入社時に特別必要ありません。
														</td>
														<td>
															<ul>
																<li>３級整備士以上の資格</li>
																<li>普通自動車免許（AT車限定不可）</li>
																<li>ディーラー経験者優遇します</li>
															</ul>
														</td>
													</tr>
													<tr>
														<td>キャリアエキスパート</td>
														<td colspan="2">
															<b>（１つ要件を満たす事項があれば大丈夫です）</b>
															<ul>
																<li>
																	自動車整備経験がありながら金属加工や溶接などの特殊な技術がある。
																</li>
																<li>
																	エンジン、サスペンション、ボディなどメーカー経験がありパーツ開発力がある。
																</li>
																<li>
																	レースメカニック経験により特殊な知識や技術を有している。
																</li>
																<li>
																	コンピューターや電子制御に精通している。
																</li>
																<li>
																	自動車整備経験がありながら金属加工や溶接などの特殊な技術がある。
																</li>
																<li>ITで集客できる知識や技術を有している。</li>
															</ul>
														</td>
													</tr>
												</table>
											</td>
										</tr>
										<tr>
											<th>雇用形態</th>
											<td>正社員</td>
										</tr>
										<tr>
											<th>給与</th>
											<td>月給：200,000円〜500,000円</td>
										</tr>
										<!--<tr>
											<th>試用期間</th>
											<td>試用期間あり（６ヶ月）</td>
										</tr>-->
										<tr>
											<th>勤務地</th>
											<td>
												<p>京都府京都市山科区（本社）</p>
												<dl>
													<dt>【アクセス】</dt>
													<dd>
														<ul>
															<li>JR山科駅から徒歩20分</li>
															<li>地下鉄東西線 山科駅から20分</li>
															<li>京阪大津線 四ノ宮駅から7分</li>
															<li>自動車、バイク通勤可</li>
														</ul>
													</dd>
												</dl>
												<dl>
													<dt>【受動喫煙防止措置の状況】</dt>
													<dd>
														<ul>
															<li>原則屋内禁煙、喫煙スペースあり</li>
														</ul>
													</dd>
												</dl>
											</td>
										</tr>
										<tr>
											<th>勤務時間・曜日</th>
											<td>
												<dl>
													<dt>【勤務時間】</dt>
													<dd>
														<ul>
															<li>
																就業時間：9:30 〜 18:30（8時間労働/休憩1時間）
															</li>
															<li>
																休日労働：要望に応じて火曜日に休日労働することができます。
															</li>
														</ul>
													</dd>
												</dl>
												<dl>
													<dt>【店舗営業時間】</dt>
													<dd>
														<ul>
															<li>営業時間：10:00 〜 18:00</li>
															<li>
																定休日：毎週月曜日、毎月第２・４火曜日休み
															</li>
															<li>
																長期休業：年末年始、GW、夏季（各5日 x
																3回、定休との連休で9日連休）
															</li>
														</ul>
													</dd>
												</dl>
											</td>
										</tr>
										<tr>
											<th>休暇・休日</th>
											<td>
												<ul>
													<li>年間休日：119日</li>
													<li>週休２日制 月曜日、火曜日（応相談）</li>
													<li>
														長期休暇：年末年始、GW、夏季（定休との連休で9日連休）
													</li>
													<li>
														有給休暇：あり（法令に準ずる）※
														土曜・日曜日に取得することが可能。
													</li>
													<li>休日労働されたい希望の方は応相談</li>
												</ul>
											</td>
										</tr>
										<tr>
											<th>待遇・福利厚生</th>
											<td>
												<ul>
													<li><b>賞与：年２回</b></li>
													<li>
														社会保険完備（健康保険、厚生年金、労災保険、雇用保険）
													</li>
													<li>制服貸与（安全靴含む）</li>
													<li>
														プロモーション用のTシャツおよびジャンパー等の支給
													</li>
													<li>社員割引き：部品</li>
												</ul>
											</td>
										</tr>
										<tr>
											<th>選考方法</th>
											<td>書類選考・面接審査</td>
										</tr>
										<tr>
											<th>会社概要</th>
											<td>
												<dl>
													<dt>会社名</dt>
													<dd class="mt-2 ml-2">有限会社アシスト</dd>
													<dt>所在地</dt>
													<dd class="mt-2 ml-2">
														〒607-8067 京都府京都市山科区音羽前田町48-1（
														<a href="http://goo.gl/ryxqF0" target="_blank"
															>Google Maps</a
														>）
													</dd>
													<dt>代表者名</dt>
													<dd class="mt-2 ml-2">代表取締役 高澤 嘉英</dd>
												</dl>
												<a href="/company" class="btn-link btn-outline-dark"
													><i class="fas fa-link mr-2"></i
													>会社概要ページへ移動</a
												>
											</td>
										</tr>
									</tbody>
								</table>

								<p class="text-center">
									<a
										href="https://form.run/@assist-recruit"
										target="_blank"
										class="btn contact-btn mx-3 mt-3"
										>応募フォームへ進む</a
									>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- ##### Contact Form Area End ##### -->
	</div>
</template>

<script>
//import Sidebar from "@/components/Sidebar.vue";
export default {
	title: "自動車整備士メカニック（BMW整備／チューニング）",
	components: {},
};
</script>

<style scoped lang="scss">
ul {
	margin-bottom: 0.6rem;
	li {
		padding: 0;
	}
}
dl {
	margin-bottom: 1.4rem;
	dt {
		margin-bottom: 0.6rem;
	}
	dd {
		margin: 0 0 0.2rem 1rem;
	}
}
.btn {
	width: 80%;
}
p {
	color: #212529;
}
table {
	th {
		width: 20%;
	}
}
</style>
